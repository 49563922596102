<template>
  <div class="account-journal">
    <van-sticky>
      <div class="header-wrap" :style="`background: url(${$AppData.config.backgroundImgUrl}) 0 0/100% auto no-repeat;`">
        <van-config-provider :theme-vars="themeVars">
          <van-nav-bar :title="$t('title.accountDetail')" left-arrow @click-left="goBack" :border="false"></van-nav-bar>
        </van-config-provider>
        <div class="account-info">
          <div class="info-item">
            <div class="info-item-label">
              <p>{{$t('field.available')}}</p>
              <p>({{$AppData.config.appCoin}})</p>
            </div>
            <p class="info-item-text">{{accountInfo.balanceStr}}</p>
          </div>
          <div class="info-item">
            <div class="info-item-label">
              <p>{{$t('field.frozen')}}</p>
              <p>({{$AppData.config.appCoin}})</p>
            </div>
            <p class="info-item-text">{{accountInfo.freezeStr}}</p>
          </div>
        </div>
      </div>
    </van-sticky>
    <van-pull-refresh v-model="refreshing" @refresh="refreshData">
      <div class="order-list">
        <van-list
          v-if="dataList.length >0"
          v-model:loading="loading"
          :finished="finished"
          :finished-text="$t('common.noMore')"
          @load="loadData"
        >
          <div class="journal-block" v-for="item in dataList" :key="item.sn">
            <van-cell-group>
              <van-cell v-if="!$AppData.config.themeType" :title="$t('field.orderNumber')" :value="item.sn" class="journal-title" value-class="journal-sn"/>
              <div class="journal-detail">
                <van-cell v-if="$AppData.config.themeType" :title="$t('field.orderNumber')" :value="item.sn" class="journal-detail-row"/>
                <van-cell :title="$t('field.orderTime')" :value="item.tradeTimeStr" :border="false" class="journal-detail-row" />
                <van-cell :title="$t('field.type')" :value="item.typeStr" :border="false" class="journal-detail-row" />
                <van-cell :title="$AppData.config.appCoin" :value="item.amountStr" :border="false" class="journal-detail-row" />
              </div>
            </van-cell-group>
          </div>
        </van-list>
        <div v-else>
          <van-empty :description="$t('common.noData')"></van-empty>
        </div>
      </div>
    </van-pull-refresh>
  </div>
</template>

<script>
import { ref } from 'vue';
import { useRouter } from 'vue-router';
import i18n from '@/assets/lang/index';
import { queryAccountInfo, queryJournalList } from "@/api";

export default {
  setup() {
    const router = useRouter();
    const goBack = () => router.back();
    
    const loading = ref(false);
    const finished = ref(false);
    const refreshing = ref(false);
    const dataList = ref([]);
    
    const themeVars = {
      navBarBackgroundColor: 'transparent',
      navBarTitleTextColor: '#fff',
      navBarIconColor: '#fff',
    };
    return {
      goBack,
      loading,
      finished,
      refreshing,
      dataList,
      themeVars,
    };
  },
  
  data() {
    return {
      current: 1,
      accountInfo: {},
    };
  },
  
  mounted() {
    this.fetchAccountInfo();
    this.fetchJournal();
  },

  methods: {
    fetchAccountInfo() {
      queryAccountInfo().then(res => {
        this.accountInfo = res.data || {};
      });
    },
    fetchJournal() {
      queryJournalList({
        pageSizeStr: 20,
        pageNumberStr: this.current,
      }).then(res => {
        this.dataList = this.dataList.concat(res.data || []);
        this.current = res.pageNumber + 1;
        this.loading = false;
        if (res.totalNumber <= res.pageNumber * res.pageSize) {
          this.finished = true;
        }
      });
    },
    loadData() {
      if (this.refreshing) {
        this.dataList = [];
        this.current = 1;
        this.refreshing = false;
      }
      this.fetchJournal();
    },
    refreshData() {
      // 清空列表数据
      this.finished = false;
            
      // 重新加载数据
      // 将 loading 设置为 true，表示处于加载状态
      this.loading = true;
      this.loadData();
    },
  },
};
</script>

<style lang="less">
.account-journal {
  height: 100vh;
  .account-info {
    display: flex;
    text-align: center;
    padding: 6px 0;
    margin-top: 20px;
    background: transparent;
  }
  .header-wrap { min-height: 160px; }
  .info-item {
    flex-grow: 1;
    .info-item-label {
      color: #fff;
      font-size: 13px;
      margin-bottom: 6px;
    }
    .info-item-text {
      color: #fff;
      font-size: 17px;
      font-weight: bold;
    }
  }
}
.theme1 .account-journal {
  background: #fff;
}
</style>
